import { BackDropLoading } from "@components";
import { getToken } from "@utils";
import { AdminContext } from "context";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import { Suspense, useContext } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { adminRoutes, renderRoutes } from "routes";

const App = () => {
  const token = getToken("AccessToken");
  const { isInitialized } = useContext(AdminContext);
  return (
    <>
      <BackDropLoading isLoading={!isInitialized} />
      <Routes>
        <Route
          path="auth/*"
          element={
            <Suspense fallback={<BackDropLoading isLoading />}>
              <AuthLayout />
            </Suspense>
          }
        />
        <Route
          path="admin/*"
          element={
            <Suspense fallback={<BackDropLoading isLoading />}>
              <AdminLayout />
            </Suspense>
          }
        >
          <Route path="*" element={<Navigate to="users" />} />
          {isInitialized ? renderRoutes(adminRoutes) : null}
        </Route>
        <Route
          path="*"
          element={<Navigate to={token ? "/admin" : "/auth"} />}
        />
      </Routes>
    </>
  );
};

export default App;
