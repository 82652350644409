export enum EActionLoggingType {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
  DELETE = "DELETE",
  IMPORT = "IMPORT",
}

export enum EEntityType {
  USER = "user",
  PARTNER = "partner",
  PROMO_CODE = "promo_code",
  SUBSCRIPTION = "subscription",
  EMPLOYER = "employer",
  ELIGIBLE_EMPLOYEE = "eligible_employee",
}

export enum EActionLogging {
  Id = "_id",
  CreatedAt = "createdAt",
  UserId = "userId",
  Action = "action",
  EntityType = "entityType",
  EntityId = "entityId",
  PreviousValue = "previousValue",
  NewValue = "newValue",
}

export const actionTypeList = [
  { label: "DELETE", value: EActionLoggingType.DELETE },
  { label: "CREATE", value: EActionLoggingType.CREATE },
  { label: "UPDATE", value: EActionLoggingType.UPDATE },
  { label: "IMPORT", value: EActionLoggingType.IMPORT },
];

export const subRoleEmployerEntities = [
  { label: "Promote code", value: EEntityType.PROMO_CODE },
  { label: "Eligible employee", value: EEntityType.ELIGIBLE_EMPLOYEE },
];

export const effectEntities = [
  ...subRoleEmployerEntities,
  { label: "Employer", value: EEntityType.EMPLOYER },
  { label: "Partner", value: EEntityType.PARTNER },
  { label: "Subscription", value: EEntityType.SUBSCRIPTION },
  { label: "User", value: EEntityType.USER },
];

export const actionLoggingField: {
  [key: string]: { label: string; class: string };
} = {
  [EActionLogging.CreatedAt]: { label: "Created Date", class: "md:w-[10%]" },
  [EActionLogging.UserId]: { label: "Admin User", class: "md:w-[20%]" },
  [EActionLogging.Action]: { label: "Action Type", class: "md:w-[10%]" },
  [EActionLogging.EntityType]: {
    label: "Entity/Setting Affected",
    class: "md:w-[10%]",
  },
  [EActionLogging.PreviousValue]: {
    label: "Previous Value",
    class: "md:w-[20%]",
  },
  [EActionLogging.NewValue]: {
    label: "New Value",
    class: "md:w-[20%]",
  },
};
