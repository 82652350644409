import { AdminContext } from "context";
import { ERoleName, ESubRole } from "enums";
import { ReactElement, ReactNode, useContext, useMemo } from "react";

type IProps = {
  children: ReactElement | ReactNode;
  roles?: ERoleName[];
  subRoles?: ESubRole[];
  onPermissionCheck?: (val: boolean) => void;
  allowView?: boolean;
};

export const PermissionDirective = ({
  children,
  roles = [],
  subRoles = [],
  onPermissionCheck,
  allowView,
}: IProps): ReactElement => {
  const { roleInfo } = useContext(AdminContext);

  const hasPermission = useMemo(
    () =>
      allowView ||
      roleInfo?.role === ERoleName.ADMIN ||
      roles.includes(roleInfo?.role) ||
      subRoles.includes(roleInfo?.subRole),
    [allowView, roleInfo, roles, subRoles]
  );

  onPermissionCheck?.(hasPermission);

  return hasPermission ? <>{children}</> : null;
};
