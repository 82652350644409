import { Navbar, Sidebar } from "@components";
import { getToken } from "@utils";
import { AdminContext } from "context";
import { useContext, useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { getAdminRoleInfoService } from "services";

export default function Admin(props: { [x: string]: any }) {
  const { ...rest } = props;
  const { setIsBackDropLoading, setRoleInfo } = useContext(AdminContext);
  const [open, setOpen] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (!getToken("AccessToken")) navigate("/auth");
    window.addEventListener("resize", () =>
      window.innerWidth < 1200 ? setOpen(false) : setOpen(true)
    );
    return () => window.removeEventListener("resize", () => {});
  }, []);

  useEffect(() => {
    (async () => {
      try {
        setIsBackDropLoading(true);
        const { data } = await getAdminRoleInfoService();
        setRoleInfo(data);
      } catch (error) {
      } finally {
        setIsBackDropLoading(false);
      }
    })();
  }, []);

  return (
    <div className="flex h-full w-full">
      <Sidebar open={open} onClose={() => setOpen(false)} />
      <div className="h-[fit-content] min-h-screen w-full bg-lightPrimary dark:!bg-navy-900">
        <main
          className={`mx-[12px] h-full flex-none transition-all md:pr-2 xl:ml-[300px]`}
        >
          <div className="h-full">
            <Navbar onOpenSidenav={() => setOpen(true)} {...rest} />
            <div className="pt-5s mx-auto mb-auto min-h-[84vh] p-2 md:pr-2">
              <Outlet />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
