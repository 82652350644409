import { apiInstance } from "@apis";
import { PAGE_SIZE } from "@constants";
import { createRandomUUID, getFilterDateParams } from "@utils";
import { EApiUrl } from "enums";
import moment from "moment";
import {
  IBaseRes,
  IFilterDate,
  IPagable,
  IRedemptionRes,
  AdminRoleInfo,
  ISortTable,
  ISubscriptionRes,
  IUserDetailRes,
  IUserRes,
  IUserUpdate,
} from "types";

const size = PAGE_SIZE;

export const getUserList = async (
  filterDate: IFilterDate,
  search: string,
  page: number,
  sort: ISortTable,
  pageSize?: number
) => {
  const filterPayload = {
    ...(filterDate?.startDate && { startDate: filterDate.startDate }),
    ...(filterDate?.endDate && { endDate: filterDate.endDate }),
  };
  const { data } = await apiInstance.get<IBaseRes<IPagable<IUserRes[]>>>(
    EApiUrl.USER_URL,
    {
      params: {
        search,
        ...filterPayload,
        ...sort,
        page,
        size: pageSize ?? size,
      },
    }
  );
  return data?.data;
};

export const getUserById = async (id: string) => {
  const { data } = await apiInstance.get<IBaseRes<IUserDetailRes>>(
    `${EApiUrl.USER_URL}/${id}`
  );
  return data?.data;
};

export const updateUserInfo = async (id: string, user: IUserUpdate) => {
  const { data } = await apiInstance.put(`${EApiUrl.USER_URL}/${id}`, {
    ...user,
  });
  return data;
};

export const updateAvatar = async (id: string, avatar: string | File) => {
  const formData = new FormData();
  const fileIdParam = `${createRandomUUID()}`;
  formData.append("image", avatar);
  const { data } = await apiInstance.put(
    `${EApiUrl.USER_URL}/avatar/${id}`,
    formData,
    {
      params: {
        fileId: fileIdParam,
      },
    }
  );
  return data;
};

export const deleteUserById = async (id: string) => {
  const { data } = await apiInstance.delete(`${EApiUrl.USER_URL}/${id}`);
  return data;
};

export const getSubscriptionList = async (
  filterDate: IFilterDate,
  search: string,
  page: number,
  sort: ISortTable,
  pageSize?: number
) => {
  const filterPayload = {
    ...getFilterDateParams(
      filterDate,
      "transactionStartDate",
      "transactionEndDate"
    ),
    ...getFilterDateParams(filterDate, "expiresStartDate", "expiresEndDate"),
  };
  const { data } = await apiInstance.get<
    IBaseRes<IPagable<ISubscriptionRes[]>>
  >(EApiUrl.SUBSCRIPTION_URL, {
    params: {
      search,
      ...filterPayload,
      ...sort,
      page,
      size: pageSize ?? size,
    },
  });
  return data?.data;
};

export const exportSubscriptionList = async (
  filterDate: IFilterDate,
  search: string,
  page: number,
  sort: ISortTable,
  exportAll = false
) => {
  const filterPayload = {
    ...getFilterDateParams(
      filterDate,
      "transactionStartDate",
      "transactionEndDate"
    ),
    ...getFilterDateParams(filterDate, "expiresStartDate", "expiresEndDate"),
  };
  const { data } = await apiInstance.get(`${EApiUrl.SUBSCRIPTION_URL}/export`, {
    params: {
      search,
      ...filterPayload,
      ...sort,
      page,
      size,
      exportAll,
    },
    responseType: "blob",
  });
  return data;
};

export const createSubscription = async (sub: ISubscriptionRes) => {
  const { user } = sub;
  const { data } = await apiInstance.post(
    `${EApiUrl.USER_URL}/${user}/subscription`,
    { ...sub }
  );
  return data;
};

export const getCurrentSubscription = async (id: string) => {
  const { data } = await apiInstance.get<IBaseRes<ISubscriptionRes>>(
    `${EApiUrl.USER_URL}/${id}/subscription`
  );
  return data?.data;
};

export const deleteSubscription = async (id: string) => {
  const { data } = await apiInstance.delete(
    `${EApiUrl.USER_URL}/${id}/subscription`
  );
  return data;
};

export const updateSubscriptionInfo = async (sub: ISubscriptionRes) => {
  const { _id, user, ...rest } = sub;
  const { data } = await apiInstance.put(
    `${EApiUrl.USER_URL}/${user}/subscription/${_id}`,
    { ...rest }
  );
  return data;
};

export const getAdminRoleInfoService = async () => {
  const { data } = await apiInstance.get<IBaseRes<AdminRoleInfo>>(
    `${EApiUrl.USER_URL}/info`
  );
  return data;
};

export const getRedemptionListService = async (
  filterDate: IFilterDate,
  search: string,
  page: number,
  sort: ISortTable,
  pageSize: number
) => {
  const filter = getFilterDateParams(filterDate, "startDate", "endDate");
  const { data } = await apiInstance.get<IBaseRes<IPagable<IRedemptionRes[]>>>(
    EApiUrl.REDEMPTION_URL,
    {
      params: {
        search,
        page,
        size: pageSize,
        ...filter,
        ...sort,
      },
    }
  );
  return data?.data;
};

export const getUrlExportRedemptionService = async () => {
  const timezone = moment.tz.guess();
  const { data } = await apiInstance.get<IBaseRes<string>>(
    `${EApiUrl.REDEMPTION_URL}/export/export-csv`,
    {
      params: { timezone },
    }
  );
  return data?.data;
};

export * from "./auth";
export * from "./codes";
export * from "./employer";
export * from "./partner";
export * from "./system-users";
