import { PermissionDirective } from "@components";
import { ERoleName } from "enums";
import { useMemo } from "react";
import { CiBarcode } from "react-icons/ci";
import { FaRegHandshake, FaUserGroup } from "react-icons/fa6";
import { GrUserManager, GrUserWorker } from "react-icons/gr";
import { MdOutlinePendingActions, MdRedeem } from "react-icons/md";
import { PiSubtitlesFill } from "react-icons/pi";
import { Route } from "react-router-dom";
import { IRoute } from "types";
import {
  CodeList,
  EligibleEmployees,
  EmployerList,
  PartnerList,
  RedemptionList,
  SubscriptionDetail,
  SubscriptionList,
  UserDetail,
  UserList,
} from "views";
import { ActionLoggingList } from "views/action-logging";
import { SystemUserList } from "views/system-users/SystemUserList";

export const adminRoutes: IRoute[] = [
  {
    name: "Users",
    layout: "/admin",
    icon: <FaUserGroup className="h-6 w-6" />,
    path: "users",
    component: null,
    requireRoles: [ERoleName.ADMIN],
    children: [
      {
        path: "",
        component: <UserList />,
      },
      {
        path: ":userId",
        component: <UserDetail />,
      },
    ],
  },
  // {
  //   name: "System Users",
  //   layout: "/admin",
  //   icon: <FaUserGroup className="h-6 w-6" />,
  //   path: "system-users",
  //   children: [
  //     {
  //       path: "",
  //       component: <SystemUserList />,
  //     },
  //     {
  //       path: ":userId",
  //       component: <UserDetail />,
  //     },
  //   ],
  // },
  // system user will be reopened once it is fully completed
  {
    name: "Subscription",
    layout: "/admin",
    icon: <PiSubtitlesFill className="h-6 w-6" />,
    path: "subscription",
    component: null,
    requireRoles: [ERoleName.ADMIN],
    children: [
      {
        path: "",
        component: <SubscriptionList />,
      },
      {
        path: ":userId",
        component: <SubscriptionDetail />,
      },
    ],
  },
  {
    name: "Partners",
    layout: "/admin",
    icon: <FaRegHandshake className="h-6 w-6" />,
    path: "partners",
    component: null,
    requireRoles: [ERoleName.ADMIN],
    children: [
      {
        path: "",
        component: <PartnerList />,
      },
      {
        path: ":partnerId",
        component: <PartnerList />,
      },
    ],
  },
  {
    name: "Employers",
    layout: "/admin",
    icon: <GrUserManager className="h-6 w-6" />,
    path: "employers",
    component: <EmployerList />,
    requireRoles: [ERoleName.ADMIN],
  },
  {
    name: "Eligible Employees",
    layout: "/admin",
    icon: <GrUserWorker className="h-6 w-6" />,
    path: "eligible-employees",
    component: <EligibleEmployees />,
    requireRoles: [ERoleName.ADMIN, ERoleName.SYSTEM_USER],
  },
  {
    name: "Codes",
    layout: "/admin",
    icon: <CiBarcode className="h-6 w-6" />,
    path: "codes",
    component: <CodeList />,
    requireRoles: [ERoleName.ADMIN, ERoleName.SYSTEM_USER],
  },
  {
    name: "Redemptions",
    layout: "/admin",
    icon: <MdRedeem className="h-6 w-6" />,
    path: "redemptions",
    component: <RedemptionList />,
    requireRoles: [ERoleName.ADMIN, ERoleName.SYSTEM_USER],
  },
  {
    name: "Action Logging",
    layout: "/admin",
    icon: <MdOutlinePendingActions className="h-6 w-6" />,
    path: "action-logging",
    component: <ActionLoggingList />,
    requireRoles: [ERoleName.ADMIN, ERoleName.SYSTEM_USER],
  },
];

export const renderRoutes = (routes: IRoute[]) => {
  return routes.map((route, index: number) => {
    const element = useMemo(
      () => (
        <PermissionDirective
          roles={route.requireRoles}
          subRoles={route.requireSubRoles}
        >
          {route.component}
        </PermissionDirective>
      ),
      [route.component, route.requireRoles, route.requireSubRoles]
    );

    return (
      <Route
        key={index}
        path={route.path}
        {...(element.props.children && { element })}
      >
        {route.children?.length && renderRoutes(route.children as IRoute[])}
      </Route>
    );
  });
};
