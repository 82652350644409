import DashIcon from "components/icons/DashIcon";
import { PermissionDirective } from "components/PermissionDirective";
import { AdminContext } from "context";
import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { IRoute } from "types";

export const SidebarLinks = (props: { routes: IRoute[] }): JSX.Element => {
  let location = useLocation();

  const { isFetching } = useContext(AdminContext);
  const { routes } = props;

  const activeRoute = (routeName: string) => {
    const pathname = location.pathname;
    const routeRegex = new RegExp(`(^|/)${routeName}($|/)`, "i");
    return routeRegex.test(pathname);
  };

  const createLinks = (routes: IRoute[]) => {
    return routes.map((route, index) => {
      if (route.layout === "/admin") {
        return (
          <PermissionDirective
            key={index}
            roles={route.requireRoles}
            subRoles={route.requireSubRoles}
          >
            <Link
              to={route.layout + "/" + route.path}
              className={isFetching ? "pointer-events-none opacity-50" : ""}
              tabIndex={isFetching ? -1 : undefined} // Prevent keyboard focus
            >
              <div className="relative mb-3 flex hover:cursor-pointer">
                <li
                  className="my-[3px] flex cursor-pointer items-center px-8"
                  key={index}
                >
                  <span
                    className={`${
                      activeRoute(route.path) === true
                        ? "font-bold text-brand-500 dark:text-white"
                        : "font-medium text-gray-600"
                    }`}
                  >
                    {route.icon ? route.icon : <DashIcon />}{" "}
                  </span>
                  <p
                    className={`leading-1 ml-4 flex ${
                      activeRoute(route.path) === true
                        ? "font-bold text-navy-700 dark:text-white"
                        : "font-medium text-gray-600"
                    }`}
                  >
                    {route.name}
                  </p>
                </li>
                {activeRoute(route.path) ? (
                  <div className="absolute right-0 top-px h-9 w-1 rounded-lg bg-brand-500 dark:bg-brand-400" />
                ) : null}
              </div>
            </Link>
          </PermissionDirective>
        );
      }
    });
  };

  return <>{createLinks(routes)}</>;
};

export default SidebarLinks;
