import { adminRoutes } from "routes";

const getPageName = (path: string) => {
  return adminRoutes.find((i) => i.path === path)?.name || "";
};


const formatArrayToDetail = (indexes: number[]) => {
  if (indexes.length > 10) {
    return indexes.slice(0, 10).join(", ") + ", ...";
  }
  return indexes.join(", ");
};

const capitalizeFirstLetter = (str: string) => {
  return str?.charAt(0).toUpperCase() + str?.slice(1);
};

export { getPageName, formatArrayToDetail, capitalizeFirstLetter };
